<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <!-- <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">应用名称 : </span>
              <div class="width200">
                <el-select
                  class="width250"
                  v-model="searchData.type"
                  clearable
                  @change="handleSearch"
                >
                  <el-option :value="1" label="中电e家"></el-option>
                  <el-option :value="2" label="中电e查(移动端)"></el-option>
                  <el-option :value="3" label="中电e查(PC端)"></el-option>
                </el-select>
              </div>
            </div> -->

            <!-- <div class="flex align-items marginRight10 marginBottom10">
              <span class="flex span marginRight10">蓝信渠道名称 : </span>
              <div class="width200">
                <el-select
                  filterable
                  class="width250"
                  v-model="searchData.name"
                  clearable
                  @change="handleSearch"
                  placeholder="请选择"
                  :maxTagCount="100"
                >
                  <el-option
                    v-for="item in typeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div> -->
            <!-- <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">入口地址 : </span>
              <div class="width200">
                <el-select
                  filterable
                  class="width250"
                  v-model="searchData.entryUrl"
                  clearable
                  @change="handleSearch"
                  placeholder="请选择"
                  :maxTagCount="100"
                >
                  <el-option
                    v-for="item in entryList"
                    :key="item.id"
                    :label="item.entryUrl"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div> -->
            <!-- <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
                >重置</el-button
              >
            </div> -->

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-plus"
                @click="handleCreate"
                >新增</el-button
              >
            </div>
          </div>
        </div>
      </template>
      <!-- <template slot="title" slot-scope="{ option }">
        <div class="custom-title">
          <span>自定义标题</span>
          <span class="total">总数：{{ option.total }}</span>
        </div>
      </template> -->
      <template slot="image" slot-scope="{ row }">
        <el-image
          style="width: 80px; height: 80px"
          :src="row.image"
          :preview-src-list="[row.image]"
        >
        </el-image>
      </template>
      <template slot="state" slot-scope="{ row }">
        <el-switch
          :value="row.state"
          :inactive-value="2"
          :active-value="1"
          @change="handleSwitch($event, row.id)"
        ></el-switch>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <!-- <el-button
          type="text"
          size="mini"
          icon="el-icon-view"
          @click="handleDetails(row)"
          >详情</el-button
        > -->
        <el-button
          type="text"
          size="mini"
          icon="el-icon-edit"
          @click="handleEdit(row)"
          >编辑</el-button
        >
        <el-button
          type="text"
          size="mini"
          icon="el-icon-delete"
          @click="handleDelete(row)"
          style="color: #ff001f"
          >删除</el-button
        >
      </template>
    </avue-crud>
    <el-dialog
      v-if="dialogVisible"
      :title="`${ form.id ? '修改PC端登录banner' : '新增PC端登录banner'}`"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <avue-form ref="form" v-model="form" :option="formOption">
        <template slot="name">
          <el-input
            v-model="form.name"
            maxlength="20"
            placeholder=""
            onkeyup="this.value=this.value"
            @blur="salaryDianChange($event, 'name')"
            type="text"
            clearable
            :disabled="isDetails"
          />
          <!-- onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)" -->
        </template>
        <template slot="url">
          <el-input
            v-model="form.url"
            maxlength="200"
            placeholder=""
            onkeyup="this.value=this.value"
            @blur="salaryDianChange($event, 'url')"
            type="text"
            clearable
            :disabled="isDetails"
          />
        </template>
        <template slot="image">
          <FormImgUpload
            :url="form.image"
            @upload="upload"
            :limit="1"
            :maxSize="5"
            accept="image/jpeg,image/png,image/jpg"
            :multiple="true"
          />
        </template>

        <template slot="state">
          <el-switch
            v-if="!isDetails"
            :disabled="isDetails"
            v-model="form.state"
            :inactive-value="2"
            :active-value="1"
          ></el-switch>
          <div v-else>
            {{ form.state == 1 ? "启用中" : form.state == 2 ? "停用中" : "" }}
          </div>
        </template>

        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">
            {{ isDetails ? "关闭" : "取消" }}</el-button
          >
          <el-button
            type="primary"
            v-if="!isDetails"
            icon="el-icon-check"
            @click="submit(form)"
            >确定</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  pcList,
  pcAdd,
  pcDelete,
  // urlView,
  pcUpdate,
  pcStateUpdate,
} from "@/api/banner/pc";
import { mapGetters } from "vuex";

import FormImgUpload from "@/components/formComponents/formImgUpload";

export default {
  name: "index",
  components: {
    FormImgUpload,
  },
  // computed: {
  // 	...mapGetters(['getButton'])
  // },
  data() {
    return {
      button: {},
      showAuthority: true,
      isDetails: false,
      typeList: [],
      entryList: [],
      searchData: {
        name: "",
        projectId: "",
      },
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      tableData: [], //列表数据
      showLoading: false,
      option: {
        title: "",
        titleSize: "h3",
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "banner名称",
            prop: "name",
            // fixed: "left",
            width: 150,
          },
          {
            label: "图片",
            prop: "image",
            // fixed: "left",
            width: 150,
          },
          {
            label: "地址",
            prop: "url",
          },
          {
            label: "排序",
            prop: "sort",
          },
          {
            label: "是否显示",
            prop: "state",
          },
          {
            label: "创建时间",
            prop: "createTime",
          },
          {
            label: "更新时间",
            prop: "updateTime",
            width: 150,
          },
        ],
      },

      dialogVisible: false,
      form: {
        image: [],
      },
      formOption: {
        submitBtn: false,
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "100",
        row: true,
        column: [
          {
            label: "名称",
            prop: "name",
            type: "text",
            maxlength: 20,
            span: 24,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "地址",
            prop: "url",
            type: "text",
            maxlength: 200,
            placeholder: "请输入",
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "排序",
            prop: "sort",
            type: "number",
            maxRows: 999999,
            minRows: 1,
            placeholder: "请输入",
            width: "300px",
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入排序",
                trigger: "blur",
              },
            ],
          },
          {
            label: "图片",
            prop: "image",
            type: "text",
            maxlength: 50,
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value.length === 0) {
                    callback(new Error("请上传"));
                  } else {
                    callback();
                  }
                },
              },
            ],
            // change: (e) => {
            //   if (e.value.length !== 0) {
            //     this.$refs.form.clearValidate(`image`);
            //   }
            // },
          },
          {
            label: "状态",
            prop: "state",
            type: "text",
            maxlength: 100,
            span: 24,
            rules: [
              {
                required: true,
                message: "",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      dialogPassWordVisible: false,
      dataUrl: "",
    };
  },
  created() {},
  methods: {
    //初始化
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      // localStorage.setItem("searchForm", JSON.stringify(searchForm));
      this.showLoading = true;
      pcList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.image = [...e];
      } else {
        this.form.image = [...this.form.image, e];
      }
    },
    // 添加
    handleCreate() {
      this.form.image = [];
      this.isDetails = false;
      this.form.id = "";
      this.dialogVisible = true;
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      // localStorage.removeItem("searchForm");
      this.searchData = {
        title: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    //编辑
    async handleEdit(row) {
      // console.log(row)
      this.isDetails = false;
      this.form = { ...row, image: [row.image] };
      this.form.id = row.id;
      this.dialogVisible = true;
    },

    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        pcDelete({
          id: row.id,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        type: "",
      };
    },

    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    //输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },
    // 提交
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          // if (this.isDetails) {
          //   this.dialogVisible = false;
          //   setTimeout(() => {
          //     this.resetForm();
          //   }, 500);
          //   return;
          // }
          console.log(form, "formformform");
          // return
          if (form.id) {
            pcUpdate({ ...form, image: form.image[0] })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.onLoad();
                } else {
                  this.$message.error(res.msg);
                  done();
                }
              })
              .catch(() => done());
          } else {
            pcAdd({ ...form, image: form.image[0] })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.onLoad();
                } else {
                  this.$message.error(res.msg);
                  done();
                }
              })
              .catch(() => console.log(this.$refs.myForm));
          }
          this.form.type = 1;
          // done()
        }
      });
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },

    handlePassWordClose() {
      this.dialogPassWordVisible = false;
    },
    //切换是否显示
    handleSwitch(e, id) {
      pcStateUpdate({
        id: id,
        state: e,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
        } else {
          this.$message.error(res.msg);
        }
        this.onLoad();
      });
    },
  },
};
</script>

<style scoped>
.copy {
  cursor: pointer;
  /* width: 60px; */
  margin-left: 15px;
  color: #66b1ff;
}
.tips {
  font-size: 20px;
  line-height: 1;
  margin-bottom: 30px;
}
</style>