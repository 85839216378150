import { get, put,Delete,post } from '../axios'

// banner-列表
export const pcList = params => get(`/web/banner/pc/list`,params);

// banner-新增
export const pcAdd = params => post(`/web/banner/pc/add`,params);

// banner-删除
export const pcDelete = params => Delete('/web/banner/pc/delete/' + params.id, '');

// //banner-查看
// export const pcView = params => get('/web/lanxin/url/view/' + params.id, '');

// banner-修改
export const pcUpdate = params => put(`/web/banner/pc/update`,params);

// banner-状态
export const pcStateUpdate = params => put(`/web/banner/pc/state/update`,params);
